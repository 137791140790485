// ReSharper disable CoercedEqualsUsing
import $ from 'jquery';
import LocalData from '@/support/localStorage';
import PlayerApi from '@/support/playerApi';
import { ServerDataModule } from '@/store/serverData';
import Util from '@/support/utility';
import { EnsureAmpTitleOverlay } from '@/support/amp-titleOverlay/amp-titleOverlay'; // vue must include amp-titleOverlay.css
import { IVideo, IVideoPlayer, IPlayerState, ICallbackFunction, IErrorCallbackFunction, IMuteCallbackFunction } from '@/interfaces';

const PlayerDebug = false;

let _Debug = Object.assign({}, Debug);
if (!PlayerDebug) {
    _Debug.log = () => { };
}

let titleOverlay = new EnsureAmpTitleOverlay();

interface CaptionsTrack extends amp.Player.Track {
    language: string;
}

// azure media player funcs
export default class AmsPlayer implements IVideoPlayer {
    get serverData() {
        return ServerDataModule.serverData;
    }

    static playerCount: 0;

    firstPlay = true;
    lastSeek: number = 0;
    elementId: string = 'sur-ams-player';
    videoId: string = null;
    removeVideoElement: boolean = false;
    initialized: boolean = false;
    video: IVideo = null;
    isLive = false; // video liveness may change on server
    state: IPlayerState = {
        action: null,
        firstFrame: false,
        playing: false,
        seeking: false,
        error: false,
        playbackCount: 0,
    };
    initialSeekPos: number = 0;
    initialDuration: number = 0;
    width = 640;
    height = 360;
    mute = false;
    startTime = +(new Date());
    timers = {
        downloadFailed: 0
    };
    static retryMaxWaitTime = 2 * 60 * 1000;

    getElapsedTime() {
        let now = +(new Date());
        return Math.floor((now - this.startTime) / 1000);
    }

    monitor = {
        enabled: true,
        timer: <number>0,
        intervalTimeMs: 20 * 1000,
        lastCheckTime: null,
        stalledTime: null,
    };

    callbacks = {
        onError: <IErrorCallbackFunction>null,
        onPlay: <ICallbackFunction>null,
        onPlaying: <ICallbackFunction>null,
        onPause: <ICallbackFunction>null,
        onResume: <ICallbackFunction>null,
        onEnded: <ICallbackFunction>null,
        onClick: <ICallbackFunction>null,
        onSeeking: <ICallbackFunction>null,
        onRewind: <ICallbackFunction>null,
        onSeeked: <ICallbackFunction>null,
        onMute: <IMuteCallbackFunction>null,
        onWaiting: <ICallbackFunction>null,
    };

    getPlayer(): amp.Player {
        if (!this.initialized || !this.videoId)
            return null;

        return amp(this.videoId);
    }

    constructor(elementId: string, width: number = 0, height: number = 0) {
        Debug.setDebugModule('Player_Ams', this, elementId);
        if (elementId)
            this.elementId = elementId;

        if (width > 0)
            this.width = width;

        if (height > 0)
            this.height = height;

        if (this.monitor.enabled)
            this.monitorPlayback();
    }

    playVideoDeferred(video: IVideo, autoPlay: boolean = false) {
        _Debug.log("amsplayer playVideoDeferred");

        setTimeout(() => this.playVideo(video, autoPlay), AmsPlayer.playerCount++ * 5000);
    }

    playVideo(video: IVideo, autoPlay: boolean = true) {
        if (!video)
            return;

        if (this.firstPlay) {
            this.firstPlay = false;
            this.isLive = video.isLive;
        }

        if (this.initialSeekPos > 0)
            autoPlay = false;

        _Debug.log("amsplayer", "playVideo", video.id, autoPlay, 'attempt:', this.state.playbackCount);

        this.video = video;

        this.initialized = false;
        this.state.action = null;
        this.state.playing = false;
        this.state.seeking = false;
        this.state.error = false;
        this.state.playbackCount++;

        let setup = this.createAmpOptions(autoPlay);
        this.createVideoElement();

        let player = amp(this.videoId, setup);

        this.hookPlayerEvents(player);
        this.setPlayerSource(player);

        _Debug.log("amsplayer playVideo",
            this.elementId,
            this.videoId,
            'setup',
            setup);

        this.initialized = true;
    }

    createVideoElement() {
        let $el = $('#' + this.elementId);
        $el.width(this.width);
        $el.height(this.height);

        let videoTag = $el.find('video');
        if (!videoTag[0]) {
            videoTag = $('<video />');
            videoTag.appendTo($el);
            this.removeVideoElement = true;
        }

        this.videoId = 'sur-ams-player-' + this.video.id;
        let $videoTag = $(videoTag);
        $videoTag.attr('id', this.videoId);
        $videoTag.width(this.width);
        $videoTag.height(this.height);
        $videoTag.addClass('azuremediaplayer amp-default-skin amp-big-play-centered ams-player');
    }

    createAmpOptions(autoPlay: boolean) {

        // Don't show overlay if video size is too small
        let titleOverlay = this.height <= 300 ? {} : {
            name: '<img src="/images/Watermark.png" style="padding-top: 0.5em; padding-right: 0.5em;"></img>',
            img: true,
            imgWidth: 0.20,
            horizontalPosition: "right",
            verticalPosition: "top"
        };

        let setup = <any>{
            nativeControlsForTouch: false,
            width: this.width.toString(),
            height: this.height.toString(),
            autoplay: autoPlay,
            // new items
            controls: true,
            muted: this.mute,
            playbackSpeed: {
                enabled: true,
                initialSpeed: 1.0,
                speedLevels: [
                    { name: "x4.0", value: 4.0 },
                    { name: "x3.0", value: 3.0 },
                    { name: "x2.0", value: 2.0 },
                    { name: "x1.75", value: 1.75 },
                    { name: "x1.5", value: 1.5 },
                    { name: "x1.25", value: 1.25 },
                    { name: "normal", value: 1.0 },
                    { name: "x0.75", value: 0.75 },
                    { name: "x0.5", value: 0.5 }
                ]
            },
            logo: { enabled: false },
            plugins: {
                titleOverlay: titleOverlay,
                /* Application Insights Disabled
                appInsights: {
                    metricsToTrack: [
                        'streamId',
                        //'loaded',
                        'viewed',
                        'ended',
                        'playTime',
                        //'percentsPlayed',
                        'play',
                        'pause',
                        'seek',
                        //'fullscreen',
                        'error',
                        'buffering',
                        //'bitrateQuality',
                        'playbackSummary',
                        //'downloadInfo'
                    ]
                }
                */
            }
        };

        if (this.isLive && !this.serverData.isLimitedBrowser) {
            setup.heuristicProfile = amp.Player.HeuristicProfile.LowLatency;
            _Debug.log("amsplayer", "createAmpOptions", 'Turning on profile', setup.heuristicProfile, setup);
        }

        return setup;
    }

    hookPlayerEvents(player: any) {
        player.addEventListener(amp.eventName.error, () => this.onError());
        player.addEventListener(amp.eventName.ended, () => this.onEnded());
        player.addEventListener(amp.eventName.waiting, () => this.onWaiting());
        player.addEventListener(amp.eventName.loadeddata, () => this.onLoadedData());
        player.addEventListener(amp.eventName.loadedmetadata, () => this.onLoadedMetadata());
        player.addEventListener(amp.eventName.mute, () => this.onMute());
        player.addEventListener(amp.eventName.unmute, () => this.onUnMute());
        player.addEventListener(amp.eventName.play, () => this.onPlay());
        player.addEventListener(amp.eventName.start, () => this.onStart());
        player.addEventListener(amp.eventName.playing, () => this.onPlaying());
        player.addEventListener(amp.eventName.pause, () => this.onPause());
        player.addEventListener(amp.eventName.resume, () => this.onResume());
        player.addEventListener(amp.eventName.click, () => this.onClick());
        player.addEventListener(amp.eventName.seeking, () => this.onSeeking());
        player.addEventListener(amp.eventName.seeked, () => this.onSeeked());
        player.addEventListener(amp.eventName.rewind, () => this.onRewind());
        player.addEventListener(amp.eventName.livestartupretry, () => this.onLiveStartupRetry());

        this.hookPlayerVideoBufferEvents(player);
    }

    hookPlayerVideoBufferEvents(player: any) {
        let bufferData = player.videoBufferData();
        if (bufferData) {
            _Debug.log('hookPlayerVideoBufferEvents', 'hooked', player, bufferData);
            bufferData.addEventListener(amp.bufferDataEventName.downloadfailed, () => this.onDownloadFailed());
        }
    }

    setPlayerSource(player: any) {
        let protectionInfo = null;
        if (this.video.token) {
            protectionInfo = [
                {
                    "type": "AES",
                    "authenticationToken": this.video.token,
                }
            ];
        }

        let playlist = [
            {
                src: this.video.streamUrl,
                type: "application/vnd.ms-sstr+xml",
                protectionInfo: protectionInfo,
            }
        ];

        let subTitles: CaptionsTrack[] = [];

        if (this.video.captions && this.video.captions.length) {
            this.video.captions.forEach(caption => {
                subTitles.push({
                    src: caption.url,
                    srclang: caption.languageCode,
                    language: caption.languageCode,
                    kind: "subtitles",
                    label: caption.language,
                });
            });
        }

        player.src(playlist, subTitles);

        _Debug.log("amsplayer", "playlist", playlist, "subTitles", subTitles);
    }

    showErrors(show: boolean) {
        let player = this.getPlayer();
        if (!player) return;

        if (show)
            $("#" + player.id()).find('.vjs-error-display').show();
        else
            $("#" + player.id()).find('.vjs-error-display').hide();
    }

    showSpinner(show: boolean) {
        let player = this.getPlayer();
        if (!player) return;

        if (show)
            $("#" + player.id()).find('.vjs-loading-spinner').show();
        else
            $("#" + player.id()).find('.vjs-loading-spinner').hide();
    }

    resizeVideo(width: number, height: number) {
        _Debug.log("ams-player resizeVideo", width + ' x ' + height);

        let player = this.getPlayer();
        if (!player) return;

        this.width = width;
        this.height = height;
        player.width(width);
        player.height(height);
    }

    checkPlaying(method: string) {
        if (this.serverData.expired)
            throw new Error('EXPIRED');

        _Debug.log("amsplayer checkPlaying", method, this.monitor.stalledTime, JSON.stringify(this.state));

        // Check the stream make sure its still working
        return this.checkStream(method).then(data => {
            //_Debug.log('amsplayer checkPlaying', method);

            try {
                if (!this.video) {
                    Debug.error("amsplayer checkPlaying", method, "missing video", JSON.stringify(this.state));
                    return false;
                }

                if (this.state.action == "PLAYING") {
                    _Debug.log('amsplayer checkPlaying playing', method, this.video.studio, this.video.id, JSON.stringify(this.state));
                    return false;
                }

                if (this.state.action == "ENDED") {
                    _Debug.log('amsplayer ENDED', method, this.video.studio, this.video.id, JSON.stringify(this.state));
                    return false;
                }

                let elapsedTime = this.getElapsedTime() - this.monitor.lastCheckTime;
                this.monitor.stalledTime += elapsedTime;

                _Debug.log("amsplayer checkPlaying checkStream processing", method, this.monitor.stalledTime, elapsedTime, JSON.stringify(this.state));

                // stop playback
                this.internalStopVideo();

                if (!data) {
                    Debug.error('amsplayer checkPlaying stream doesnt exist anymore', method, this.video.studio, this.video.id, JSON.stringify(this.state));

                    // Stop playing video completely - no more checking
                    this.clearMonitorPlayback('checkPlaying');

                    if (this.callbacks.onEnded)
                        this.callbacks.onEnded(this.video);

                } else if (data.isLive != this.isLive) {
                    // Video is no longer live treat as if video ended.
                    Debug.error('amsplayer checkPlaying stream isLive changed', method, this.isLive, data.isLive, this.video.studio, this.video.id, JSON.stringify(this.state), data);

                    // Stop playing video completely - no more checking
                    this.clearMonitorPlayback('checkPlaying');

                    if (this.callbacks.onEnded)
                        this.callbacks.onEnded(this.video);
                } else if (this.monitor.stalledTime * 1000 > AmsPlayer.retryMaxWaitTime) {
                    Debug.error('amsplayer checkPlaying TIMED OUT', method, this.video.studio, this.video.id, JSON.stringify(this.state));

                    // Stop playing video completely - no more checking
                    this.clearMonitorPlayback('checkPlaying');

                    if (this.callbacks.onError)
                        this.callbacks.onError(this.video, 'Timed out trying to play video', -1, 'Timed out trying to play video');

                    if (this.callbacks.onEnded)
                        this.callbacks.onEnded(this.video);
                } else {
                    _Debug.log('amsplayer checkPlaying restarting playback', method, this.monitor.stalledTime, elapsedTime, this.video.studio, this.video.id, JSON.stringify(this.state));
                    // restart playback
                    this.playVideo(this.video, true);

                    //this.monitor.stalledTime = elapsedTime;
                }
                return true;
            } catch (e) {
                _Debug.log('amsplayer checkPlaying exception', method, e, this.video.studio, this.video.id, JSON.stringify(this.state));
                return null;
            }
        });
    }

    /**
     * Checks if stream still exists after event occurred like / onPause
     */
    checkStreamStillExists(method: string) {
        if (!this.initialized)
            return;

        return this.checkStream(method).then(data => {
            //_Debug.log('amsplayer checkStreamStillExists', method);

            try {
                if (!data) {
                    Debug.error('amsplayer checkStreamStillExists stream doesnt exist anymore', method, this.video.studio, this.video.id, JSON.stringify(this.state));

                    // stop playback
                    this.internalStopVideo();

                    if (this.callbacks.onEnded)
                        this.callbacks.onEnded(this.video);

                } else if (data.isLive != this.isLive) {
                    // Video is no longer live treat as if video ended.
                    Debug.error('amsplayer checkStreamStillExists stream isLive changed', method, this.isLive, data.isLive, this.video.studio, this.video.id, JSON.stringify(this.state), data);

                    // stop playback
                    this.internalStopVideo();

                    if (this.callbacks.onEnded)
                        this.callbacks.onEnded(this.video);
                }
                return true;
            } catch (e) {
                _Debug.log('amsplayer checkStreamStillExists exception', method, e, this.video.studio, this.video.id, JSON.stringify(this.state));
                return null;
            }
        });
    }

    findCaptionsTrack(language: string, label: string = null) {
        let player = this.getPlayer();
        if (!player) return null;

        let tracks = <CaptionsTrack[]>player.textTracks();
        if (tracks) {
            for (let idx = 0; idx < tracks.length; idx++) {
                let track = tracks[idx];
                if (language && language == track.language)
                    return track;
                if (label && label == track.label)
                    return track;
            }
        }

        return null;
    }

    setCaptionsTrack(desiredTrack: any = null) {
        if (!this.video.captions || !this.video.captions.length) return;

        let player = this.getPlayer();
        if (!player) return;

        let defaultTrack = LocalData.get('player_ams.selectedCaption', {
            language: 'En',
            label: 'English'
        });

        desiredTrack = desiredTrack || defaultTrack;
        _Debug.log('amsplayer setCaptionsTrack', desiredTrack.language, desiredTrack.label);

        let track = this.findCaptionsTrack(desiredTrack.language, desiredTrack.label);
        if (track) {
            player.setActiveTextTrack(track);
            return;
        }
    }

    addCaptionsEvent() {
        let player = this.getPlayer();
        if (!player) return;

        // Hook sub titled event
        $(".vjs-menu-item.outline-enabled-control").not(".amp-menu-item").not(".vjs-texttrack-settings").click(() => {
            this.onCaptionsChanged();
        });
    }

    onCaptionsChanged() {
        let player = this.getPlayer();
        if (!player) return;

        let track = <CaptionsTrack>player.getCurrentTextTrack() || <CaptionsTrack>{
            language: null,
            label: null,
        };

        LocalData.save('player_ams.selectedCaption', {
            language: track.language,
            label: track.label,
        });
        _Debug.log('amsplayer onCaptionsChanged', track.language, track.label);
    }

    canCheckPlaying() {
        let player = this.getPlayer();
        if (!player) return false;

        return !this.serverData.expired &&
            this.monitor.enabled &&
            this.monitor.timer &&
            this.video &&
            this.state.error ||
            (!this.state.playing && this.state.action != 'PLAYING' && this.state.action != 'PAUSED' && this.state.action != 'ENDED');
    }

    monitorPlayback() {
        this.monitor.timer = setInterval(() => {
            if (!this.monitor.lastCheckTime)
                this.monitor.lastCheckTime = this.getElapsedTime();

            if (!this.canCheckPlaying()) {
                this.monitor.lastCheckTime = this.getElapsedTime();
                //_Debug.log('monitorPlayback canCheckPlaying deferred', this.state.action);
                return;
            }

            this.checkPlaying('monitorPlayback').then(() => {
                this.monitor.lastCheckTime = this.getElapsedTime();
            });

        }, this.monitor.intervalTimeMs);
    }

    clearMonitorPlayback(method: string) {
        _Debug.log('clearMonitorPlayback', method, this.state);

        if (this.monitor.timer) {
            clearInterval(this.monitor.timer);
            this.monitor.timer = 0;
        }
    }

    async checkStream(reason: string) {
        if (this.serverData.expired)
            throw new Error('EXPIRED');

        try {
            let response = await PlayerApi.checkStream(this.video);

            _Debug.log('amsplayer checkStream valid', this.video.studio, this.video.id, response);
            return response;
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error('amsplayer checkStream expired', reason, this.video.studio, this.video.id, message);
            return null;
        } finally {

        }
    }

    onError() {
        let player = this.getPlayer();
        if (!player) return;

        try {
            this.state.action = "ERROR";
            let errorDetails = player.error();
            let code = errorDetails.code;
            let message = errorDetails.message;
            this.state.error = true;

            if (code & amp.errorCode.abortedErrStart) {
                message = 'Playback aborted: 0x' + code.toString(16);
            }
            else if (code & amp.errorCode.networkErrStart) {
                message = 'Network error: 0x' + code.toString(16);

                if (this.monitor.enabled && this.monitor.timer) {
                    Debug.error("amsplayer", "onError:", errorDetails, code, message, this.video.studio, this.video.id, this.video.title);
                    // don't report network errors since monitorPlayback will restart

                    this.showErrors(false);
                    this.showSpinner(true);
                    return;
                }
            }
            else if (code & amp.errorCode.decodeErrStart) {
                message = 'Decode error: 0x' + code.toString(16);
            }
            else if (code & amp.errorCode.srcErrStart) {
                message = 'Unsupported source: 0x' + code.toString(16);
            }
            else if (code & amp.errorCode.encryptErrStart) {
                message = 'Encrypted video error: 0x' + code.toString(16);
            }
            else if (code & amp.errorCode.srcPlayerMismatchStart) {
                message = 'Player mismatch: 0x' + code.toString(16);
            }
            else {
                message = 'Unknown error: 0x' + code.toString(16);
            }

            this.showErrors(true);

            Debug.error("amsplayer", "onError:", errorDetails, code, message, this.video.studio, this.video.id, this.video.title);

            if (this.callbacks.onError)
                this.callbacks.onError(this.video, <any>errorDetails, code, message);

            this.stopVideo();
        } catch (e) {
            Debug.error("amsplayer", "onError: exception", e, this.video.studio, this.video.id, this.video.title);
        }
    }

    onEnded() {
        try {
            _Debug.log("amsplayer", "onEnded:", this.video.studio, this.video.id, this.video.title);

            this.state.action = "ENDED";
            this.state.playing = false;
            this.state.seeking = false;

            // Stop playing video completely - no more checking
            this.clearMonitorPlayback('onEnded');

            this.stopVideo();

            if (this.callbacks.onEnded)
                this.callbacks.onEnded(this.video);
        } catch (e) {
            _Debug.log("amsplayer", "onEnded: exception", e, this.video.studio, this.video.id, this.video.title);
        }
    }

    onWaiting() {
        try {
            this.state.action = "BUFFERING";
            this.state.playing = false;

            _Debug.log("amsplayer", "onWaiting:", this.video.studio, this.video.id, this.video.title);
            // TODO: send buffering event to serverData
            if (this.callbacks.onWaiting)
                this.callbacks.onWaiting(this.video);

        } catch (e) {
            _Debug.log("amsplayer", "onWaiting: exception", e, this.video.studio, this.video.id, this.video.title);
        }
    }

    onLoadedData() {
        try {
            this.state.action = "LOADED";

            this.doInitialSeek();

            this.hookPlayerVideoBufferEvents(this.getPlayer());

            _Debug.log("amsplayer", "onLoadedData:", this.video.studio, this.video.id, this.video.title);
        } catch (e) {
            _Debug.log("amsplayer", "onLoadedData: exception", e, this.video.studio, this.video.id, this.video.title);
        }
    }

    onLoadedMetadata() {
        try {
            this.state.action = "LOADED";

            if (this.video.captions) {
                this.setCaptionsTrack();
                this.addCaptionsEvent();
            }

            _Debug.log("amsplayer", "onLoadedMetadata:", this.video.studio, this.video.id, this.video.title);
        } catch (e) {
            _Debug.log("amsplayer", "onLoadedMetadata: exception", e, this.video.studio, this.video.id, this.video.title);
        }
    }

    onMute() {
        try {
            this.mute = true;

            _Debug.log("amsplayer", "onMute:", this.video.studio, this.video.id, this.video.title);
            if (this.callbacks.onMute)
                this.callbacks.onMute(this.video, true);
        } catch (e) {
            _Debug.log("amsplayer", "onMute: exception", e, this.video.studio, this.video.id, this.video.title);
        }
    }

    onUnMute() {
        try {
            this.mute = false;

            _Debug.log("amsplayer", "onUnMute:", this.video.studio, this.video.id, this.video.title);
            if (this.callbacks.onMute)
                this.callbacks.onMute(this.video, false);
        } catch (e) {
            _Debug.log("amsplayer", "onUnMute: exception", e, this.video.studio, this.video.id, this.video.title);
        }
    }

    onPlay() {
        try {
            this.state.action = "PLAY";

            if (this.mute) {
                let player = this.getPlayer();
                if (player)
                    player.muted(true);
            }

            _Debug.log("amsplayer", "onPlay:", this.video.studio, this.video.id, this.video.title);
            if (this.callbacks.onPlay)
                this.callbacks.onPlay(this.video);
        } catch (e) {
            _Debug.log("amsplayer", "onPlay: exception", e, this.video.studio, this.video.id, this.video.title);
        }
    }

    onStart() {
        try {
            this.state.action = "PLAYING";
            this.state.firstFrame = true;
            this.state.playing = true;
            this.state.seeking = false;
            this.monitor.stalledTime = 0;
            this.clearError('onStart');

            _Debug.log("amsplayer", "onStart:", this.video.studio, this.video.id, this.video.title);
            if (this.callbacks.onPlaying)
                this.callbacks.onPlaying(this.video);
        } catch (e) {
            _Debug.log("amsplayer", "onStart: exception", e, this.video.studio, this.video.id, this.video.title);
        }
    }

    onPlaying() {
        try {
            this.state.action = "PLAYING";
            this.state.firstFrame = true;
            this.state.playing = true;
            this.state.seeking = false;
            this.monitor.stalledTime = 0;
            this.clearError('onPlaying');

            _Debug.log("amsplayer", "onPlaying:", this.video.studio, this.video.id, this.video.title);

            if (this.callbacks.onPlaying)
                this.callbacks.onPlaying(this.video);
        } catch (e) {
            _Debug.log("amsplayer", "onPlaying: exception", e, this.video.studio, this.video.id, this.video.title);
        }
    }

    onPause() {
        try {
            this.state.action = "PAUSED";
            this.state.playing = false;

            _Debug.log("amsplayer", "onPause:", this.video.studio, this.video.id, this.video.title);
            if (this.callbacks.onPause)
                this.callbacks.onPause(this.video);
        } catch (e) {
            _Debug.log("amsplayer", "onPause: exception", e, this.video.studio, this.video.id, this.video.title);
        }
    }

    onResume() {
        try {
            this.state.action = "RESUMED";
            this.state.seeking = false;
            this.state.playing = true;

            _Debug.log("amsplayer", "onResume:", this.video.studio, this.video.id, this.video.title);
            if (this.callbacks.onResume)
                this.callbacks.onResume(this.video);

        } catch (e) {
            _Debug.log("amsplayer", "onResume: exception", e, this.video.studio, this.video.id, this.video.title);
        }
    }

    onClick() {
        try {
            _Debug.log("amsplayer", "onClick:", this.video.studio, this.video.id, this.video.title);
            if (this.callbacks.onClick)
                this.callbacks.onClick(this.video);
        } catch (e) {
            _Debug.log("amsplayer", "onClick: exception", e, this.video.studio, this.video.id, this.video.title);
        }
    }

    onSeeking() {
        try {
            this.state.seeking = true;
            this.state.playing = false;

            _Debug.log("amsplayer", "onSeeking:", this.video.studio, this.video.id, this.video.title);
            if (this.callbacks.onSeeking)
                this.callbacks.onSeeking(this.video);
        } catch (e) {
            _Debug.log("amsplayer", "onSeeking: exception", e, this.video.studio, this.video.id, this.video.title);
        }
    }

    onSeeked() {
        try {
            this.state.seeking = false;

            _Debug.log("amsplayer", "onSeeked:", this.video.studio, this.video.id, this.video.title);
            if (this.callbacks.onSeeked)
                this.callbacks.onSeeked(this.video);

            this.doInitialSeek();

        } catch (e) {
            _Debug.log("amsplayer", "onSeeked: exception", e, this.video.studio, this.video.id, this.video.title);
        }
    }

    onRewind() {
        try {
            this.state.seeking = true;
            this.state.playing = false;

            _Debug.log("amsplayer", "onRewind:", this.video.studio, this.video.id, this.video.title);
            if (this.callbacks.onRewind)
                this.callbacks.onRewind(this.video);
        } catch (e) {
            _Debug.log("amsplayer", "onRewind: exception", e, this.video.studio, this.video.id, this.video.title);
        }
    }

    onLiveStartupRetry() {
        try {
            _Debug.log("amsplayer", "onLiveStartupRetry:", this.video.studio, this.video.id, this.video.title);
        } catch (e) {
            _Debug.log("amsplayer", "onLiveStartupRetry: exception", e, this.video.studio, this.video.id, this.video.title);
        }
    }

    /**
     * Video buffer download failed event
     *      Check if stream still exists & isLive matches.
     * */
    onDownloadFailed() {
        try {
            _Debug.log("amsplayer", "onDownloadFailed:", this.video.studio, this.video.id, this.video.title);

            if (!this.timers.downloadFailed) {
                this.timers.downloadFailed = setTimeout(() => {
                    this.checkStreamStillExists('onDownloadFailed');
                    this.timers.downloadFailed = 0;
                }, 5000);
            }

        } catch (e) {
            _Debug.log("amsplayer", "onDownloadFailed: exception", e, this.video.studio, this.video.id, this.video.title);
        }
    }

    seekVideo(seekPos) {
        _Debug.log("amsplayer", "seeking to pos:", seekPos);

        let player = this.getPlayer();
        if (!player) return;

        player.currentTime(seekPos); // time in seconds
        this.lastSeek = 0;
    }

    getPosition() {
        let player = this.getPlayer();
        if (!player) return 0;

        return player.currentTime();
    }

    muteVideo(mute: boolean) {
        let player = this.getPlayer();
        if (!player) return 0;

        player.muted(mute || false);
    }

    getState() {
        let player = this.getPlayer();
        if (!player) return null;

        return this.state;
    }

    pauseVideo() {
        let player = this.getPlayer();
        if (!player) return;

        player.pause();
    }

    /* stop video and clear timer */
    stopVideo() {
        _Debug.log("amsplayer", "stop video");
        this.internalStopVideo();

        this.clearMonitorPlayback('stopVideo');
    }

    /* internal stop video ONLY - keep monitor playback timer going */
    internalStopVideo() {
        _Debug.log("amsplayer", "internalStopVideo");

        let player = this.getPlayer();
        if (!player) return;

        player.dispose();
        if (this.removeVideoElement)
            $('#' + this.videoId).remove();

        player = null;
        this.initialized = false;
    }

    clearError(method: string) {
        if (this.state.error) {
            _Debug.log("clearing error", method);
            this.state.error = false;
        }
    }

    doInitialSeek() {
        if (this.initialSeekPos <= 0) return;

        let player = this.getPlayer();
        if (!player) return;

        player.currentTime(this.initialSeekPos);
        player.play();
        _Debug.log('amsplayer', 'initial seek:', this.video.studio, this.video.id, this.initialSeekPos, this.initialDuration);
        this.initialSeekPos = 0;

        if (this.initialDuration > 0) {
            setTimeout(() => {
                let player = this.getPlayer();
                if (!player) return;

                player.pause();
                _Debug.log('amsplayer', 'duration expired:', this.video.studio, this.video.id, this.video.title);
            }, (this.initialDuration + 2) * 1000);

            this.initialDuration = 0;
        }
    }

    testRawPlayer(video: IVideo) {
        var myOptions = {
            "nativeControlsForTouch": false,
            controls: true,
            autoplay: true,
            width: "640",
            height: "400",
        };

        let player = amp('azuremediaplayer', myOptions);

        let protectionInfo = null;
        if (video.token) {
            protectionInfo = [
                {
                    "type": "AES",
                    "authenticationToken": video.token,
                }
            ];
        }

        player.src([
            {
                "src": video.streamUrl,
                "type": "application/vnd.ms-sstr+xml",
                protectionInfo: protectionInfo,
            }
        ]);
        Debug.error("amsplayer testRawPlayer", "options", Util.dumpJson(myOptions));

        player = null;
        return true;
    }

}
// ReSharper restore CoercedEqualsUsing
